import React, { ReactNode } from 'react';
import { withStyles, Theme, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, AgGridActions, AgGridSwitch, BaseGrid, AgGridCheckBox } from '@wings-shared/custom-ag-grid';
import { inject, observer } from 'mobx-react';
import { styles } from './UVGOSettings.style';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { action, observable } from 'mobx';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { GridOptions, ColDef, GridReadyEvent, RowNode, ValueFormatterParams } from 'ag-grid-community';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { UvgoSettingsStore, UvgoSettings, UVGO_SETTING } from '../Shared';
import {
  DATE_FORMAT,
  IClasses,
  UIStore,
  Utilities,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { ConfirmDialog, CustomLinkButton, ImportDialog } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  uvgoSettingsStore?: UvgoSettingsStore;
};

const filtersSetup: IBaseGridFilterSetup<UVGO_SETTING> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(UVGO_SETTING),
  defaultFilterType: UVGO_SETTING.NAME,
};

@inject('uvgoSettingsStore')
@observer
class UVGOSettings extends BaseGrid<Props, UvgoSettings> {
  @observable private recurringType: boolean = false;
  @observable private settingType: boolean = false;
  constructor(props) {
    super(props, filtersSetup);
    this.data = [];
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private rowData() {
    const users = JSON.parse(JSON.stringify(this.data));
    let filteredUsers = users;
    if (this.recurringType) {
      filteredUsers = users.filter(a => a.settingType.value == 'Recurring').slice();
    }

    if (this.settingType) {
      filteredUsers = users.filter(a => a.settingType.value == 'Setting').slice();
    }
    return this.recurringType && this.settingType ? users : filteredUsers;
  }

  /* istanbul ignore next */
  loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.getUvgoSettings()
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe((data: UvgoSettings[]) => (this.data = data));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Enable/Disable',
      field: 'isEnabled',
      cellRenderer: 'switchRenderer',
      cellRendererParams: {
        isReadOnly: false,
      },
    },
    {
      headerName: 'Key',
      field: 'id',
    },
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Assembly Name',
      field: 'assemblyName',
    },
    {
      headerName: 'Set Type',
      field: 'settingType',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.value || '';
      },
    },
    {
      headerName: 'Area',
      field: 'area',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.value || '';
      },
    },
    {
      headerName: 'Published',
      field: 'isPublished',
      filter: true,
      filterParams: { applyMiniFilterWhileTyping: true },
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Last Connected',
      field: 'lastConnectedOn',
      valueFormatter: ({ value }: ValueFormatterParams) => Utilities.getformattedDate(value, DATE_FORMAT.GRID_DISPLAY),
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      minWidth: 160,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: (node: RowNode) => [
          {
            title: 'Edit',
            action: GRID_ACTIONS.EDIT,
            to: node => `/general/uvgo-settings/${node.data?.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Delete',
            action: GRID_ACTIONS.DELETE,
            isHidden: Boolean(node.data.isPublished),
          },
          {
            title: 'Publish',
            action: GRID_ACTIONS.PUBLISH,
            isHidden: Boolean(node.data.isPublished),
          },
          {
            title: 'Export',
            action: GRID_ACTIONS.EXPORT,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number, node: RowNode) => {
          this.gridActions(action, rowIndex);
        },
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    return {
      ...this._gridOptionsBase({
        context: this,
        columnDefs: this.columnDefs,
        isEditable: false,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => this.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      doesExternalFilterPass: node => {
        const { id, name, description } = node.data as UvgoSettings;
        return (
          !id ||
          this._isFilterPass({
            [UVGO_SETTING.NAME]: name,
            [UVGO_SETTING.DESCRIPTION]: description,
          })
        );
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        switchRenderer: AgGridSwitch,
        checkBoxRenderer: AgGridCheckBox,
      },
      onGridReady: (event: GridReadyEvent) => {
        event.api.setDatasource({ getRows: () => this.loadInitialData() });
        this.gridApi = event.api;
        this.columnApi = event.columnApi;
      },
      pagination: true,
    };
  }

  /* istanbul ignore next */
  onSwitchChangeHandler(rowIndex: number, isEnabled: boolean): void {
    const settingFromGrid = this._getTableItem(rowIndex);
    const setting = new UvgoSettings({ ...settingFromGrid, isEnabled });
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.upsertUvgoSettings(setting, false)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => this._updateTableItem(rowIndex, setting),
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  @action
  private deleteUvgoSetting(uvgoSetting: UvgoSettings): void {
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.deleteUvgoSetting(uvgoSetting.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        }),
        filter((isDeleted: boolean) => isDeleted)
      )
      .subscribe(
        () => {
          this._removeTableItems([ uvgoSetting ]);
          AlertStore.info('Setting deleted successfully');
          this.loadInitialData();
        },
        (error: AxiosError) => AlertStore.info(error.message)
      );
  }

  /* istanbul ignore next */
  @action
  private exportUsers(rowIndex: number): void {
    const settingFromGrid = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.exportUserSetting(settingFromGrid.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe((file: File) => {
        const url = window.URL.createObjectURL(new Blob([ file ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${settingFromGrid.id}.uvgosettings.json`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  }

  /* istanbul ignore next */
  @action
  private isPublished(rowIndex: number, isPublished: boolean): void {
    const settingFromGrid = this._getTableItem(rowIndex);
    const setting = new UvgoSettings({ ...settingFromGrid, isPublished });
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.upsertUvgoSettings(setting, false)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: () => this._updateTableItem(rowIndex, setting),
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    const uvgoSetting = this._getTableItem(rowIndex);
    if (gridAction === GRID_ACTIONS.DELETE) {
      ModalStore.open(
        <ConfirmDialog
          title="Delete uvGO Setting"
          message="Are you sure you want to delete this uvGO setting?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => this.deleteUvgoSetting(uvgoSetting)}
        />
      );
    }
    if (gridAction === GRID_ACTIONS.EXPORT) {
      ModalStore.open(
        <ConfirmDialog
          title="Export uvGO Setting"
          message="Are you sure you want to Export this uvGO setting?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => this.exportUsers(rowIndex)}
        />
      );
    }
    if (gridAction === GRID_ACTIONS.PUBLISH) {
      ModalStore.open(
        <ConfirmDialog
          title="Publish uvGO Setting"
          message="Are you sure you want to publish this uvGO setting?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => this.isPublished(rowIndex, true)}
        />
      );
    }
  }

  @action
  setRecurringType(value: boolean) {
    this.recurringType = value;
  }

  @action
  setSettingType(value: boolean) {
    this.settingType = value;
  }

  /* istanbul ignore next */
  private uploadImportData(file: File): void {
    const { uvgoSettingsStore } = this.props;
    UIStore.setPageLoader(true);
    uvgoSettingsStore
      ?.uploadImportData(file)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: response => {
          if (response) {
            AlertStore.info('Import uvGO Setting Successfully');
            this.loadInitialData();
          }
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  private importUser(): void {
    ModalStore.open(
      <ImportDialog
        title="Import uvGO Setting"
        btnText="Import"
        fileType="json"
        isLoading={() => this.isLoading}
        onUploadFile={file => this.uploadImportData(file)}
      />
    );
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.subSection}>
            <SettingsApplicationsIcon className={classes.icon} />
            <Typography component="h3" className={classes.heading}>
              uvGO Settings
            </Typography>
          </div>
          <div>
            <SearchHeader
              searchPlaceHolder={this.searchPlaceHolder}
              searchTypeValue={this.selectedOption}
              searchTypeOptions={this._selectOptions}
              onSearchTypeChange={option => this._setSelectedOption(option as UVGO_SETTING)}
              onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
            />
          </div>
          <div>
            <FormControlLabel
              value={this.recurringType}
              control={<Checkbox onChange={e => this.setRecurringType(e.target.checked)} />}
              label="Recurring Only"
            />
          </div>
          <div>
            <FormControlLabel
              value={this.settingType}
              control={<Checkbox onChange={e => this.setSettingType(e.target.checked)} />}
              label="Setting Only"
            />
          </div>
          <PrimaryButton
            variant="contained"
            color="primary"
            disabled={this.isProcessing}
            onClick={() => this.importUser()}
            startIcon={<CloudUploadIcon />}
          >
            Import
          </PrimaryButton>
          <div>
            <CustomLinkButton
              variant="contained"
              startIcon={<AddIcon />}
              to={VIEW_MODE.NEW.toLowerCase()}
              title="Add uvGO Setting"
              disabled={this.isProcessing}
            />
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact rowData={this.rowData()} gridOptions={this.gridOptions} />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(UVGOSettings);
