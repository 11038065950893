import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IGroupInputControls, IViewInputControl } from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import { UvgoSettingsStore, UvgoSettings, SettingOptionsModel } from '../../../Shared';
import { fields } from './Fields';
import { action, observable } from 'mobx';
import { styles } from './UvgoSettingEditor.style';
import { AreaTypeOptions, SettingTypeOptions } from '../../../Shared/fields';
import { NavigateFunction } from 'react-router';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import OptionFieldGrid from '../OptionFieldGrid/OptionFieldGrid';
import OptionField from '../OptionField/OptionField';
import { IClasses, IOptionValue, UIStore, Utilities, regex, withRouter, GRID_ACTIONS } from '@wings-shared/core';
import { DetailsEditorWrapper, EditSaveButtons, Collapsable } from '@wings-shared/layout';

type Props = {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  params?: { mode: VIEW_MODE; id: string };
  uvgoSettingsStore?: UvgoSettingsStore;
  navigate?: NavigateFunction;
};

@inject('uvgoSettingsStore')
@observer
class UvgoSettingEditor extends BaseUpsertComponent<Props, UvgoSettings> {
  @observable private uvgoSetting: UvgoSettings = new UvgoSettings({ id: '' });
  @observable private optionsField: SettingOptionsModel[] = [];
  @observable private isCronExpressionValid: boolean = false;

  constructor(props: Props) {
    super(props, fields);
    const mode: string = this.props.params?.mode.toUpperCase() || '';
    this.viewMode = VIEW_MODE[mode] || VIEW_MODE.NEW;
  }

  componentDidMount() {
    if (!this.uvgoSettingId) {
      this.setFormValues(this.uvgoSetting);
      return;
    }
    UIStore.setPageLoader(true);
    const { uvgoSettingsStore } = this.props;
    uvgoSettingsStore
      ?.getUvgoSetting(this.uvgoSettingId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(uvgoSetting => {
        this.uvgoSetting = new UvgoSettings(uvgoSetting);
        this.optionsField = this.uvgoSetting.options;
        this.setFormValues(this.uvgoSetting);
        return;
      });
  }

  /* istanbul ignore next */
  private upsertUvgoSettings(): void {
    UIStore.setPageLoader(true);
    this.props.uvgoSettingsStore
      ?.upsertUvgoSettings(this.getUpsertUvgoSetting(), this.viewMode == VIEW_MODE.NEW)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => this.navigateToUvgoSettings(),
        error: error => AlertStore.critical(error.message),
      });
  }

  private getUpsertUvgoSetting(): UvgoSettings {
    const formValues: UvgoSettings = this.form.values();
    const uvGOSetting = new UvgoSettings({
      ...this.uvgoSetting,
      ...formValues,
      options: this.optionsField,
    });
    return uvGOSetting;
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'UvgoSetting',
      inputControls: [
        {
          fieldKey: 'id',
          type: EDITOR_TYPES.TEXT_FIELD,
          isDisabled: this.viewMode == VIEW_MODE.NEW ? false : true,
          isExists: this.isExists,
        },
        {
          fieldKey: 'assemblyName',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'name',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'area',
          type: EDITOR_TYPES.DROPDOWN,
          autoSelect: false,
          options: AreaTypeOptions,
        },
        {
          fieldKey: 'settingType',
          type: EDITOR_TYPES.DROPDOWN,
          autoSelect: false,
          options: SettingTypeOptions,
          isDisabled: this.viewMode == VIEW_MODE.EDIT,
        },
        {
          fieldKey: 'description',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'cronExpression',
          type: EDITOR_TYPES.TEXT_FIELD,
          isHidden: !this.cronExpressionHidden,
          customErrorMessage: this.isCronExpressionValid ? 'The CronExpression format is invalid.' : '',
        },
      ],
    };
  }

  private get hasError(): boolean {
    return this.form.hasError || UIStore.pageLoading || this.isCronExpressionValid;
  }

  private get uvgoSettingId(): string {
    return this.props.params?.id || '';
  }

  private get isExists(): boolean {
    const id = this.getField('id').value;
    if(!this.props.uvgoSettingsStore){
      return false
    }
    return this.props.uvgoSettingsStore.uvgoSetting.some(
      t => Utilities.isEqual(t.id, id) && !Utilities.isEqual(t.id, this.uvgoSettingId)
    );
  }

  private get cronExpressionHidden(): boolean {
    return this.getField('settingType')?.value?.value === 'Recurring';
  }

  private onAction(action: GRID_ACTIONS): void {
    if (action === GRID_ACTIONS.CANCEL) {
      this.navigateToUvgoSettings();
      return;
    }
    this.upsertUvgoSettings();
  }

  @action
  private upsertOptionField(optionField: SettingOptionsModel) {
    if (optionField.id) {
      this.optionsField = this.optionsField.map(x => (x.id === optionField.id ? optionField : x));
      ModalStore.close();
      return;
    }

    optionField.id = Utilities.getTempId(true);
    this.optionsField = [ ...this.optionsField, optionField ];
    ModalStore.close();
  }

  @action
  private deleteOptionField(id: number) {
    this.optionsField = this.optionsField.filter(field => !Utilities.isEqual(field.id, id));
    ModalStore.close();
  }

  private navigateToUvgoSettings(): void {
    this.props.navigate && this.props.navigate('/general/uvgo-settings');
  }

  private get headerActions(): ReactNode {
    return (
      <EditSaveButtons
        disabled={this.form.hasError || UIStore.pageLoading || this.isExists || this.hasError}
        hasEditPermission={true}
        isEditMode={this.isEditable}
        onAction={action => this.onAction(action)}
      />
    );
  }

  private openOptionFieldDialog(optionField: SettingOptionsModel, viewMode: VIEW_MODE): void {
    ModalStore.open(
      <OptionField
        title={viewMode === VIEW_MODE.NEW ? 'Add Option' : 'Edit Option'}
        optionField={optionField}
        viewMode={viewMode}
        upsertOptionField={optionField => this.upsertOptionField(optionField)}
        optionsField={this.optionsField}
        uvgoSettingsStore={this.props.uvgoSettingsStore}
      />
    );
  }

  private get uvGOSettingChildGrid(): ReactNode {
    return (
      <Collapsable title="Options">
        <OptionFieldGrid
          optionsField={this.optionsField}
          openOptionFieldDialog={(optionField, viewMode) => this.openOptionFieldDialog(optionField, viewMode)}
          upsertOptionField={optionField => this.upsertOptionField(optionField)}
          deleteOptionField={(id: number) => this.deleteOptionField(id)}
        />
      </Collapsable>
    );
  }

  /* istanbul ignore next */
  @action
  protected onValueChange(value: IOptionValue | IOptionValue[], fieldKey: string): void {
    this.getField(fieldKey).set(value);
    if (Utilities.isEqual(fieldKey, 'cronExpression')) {
      this.isCronExpressionValid = !regex.cronExpression_uvGOSetting.test(value.toString());
    }
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <h2>{this.viewMode === VIEW_MODE.NEW ? 'Add uvGO Settings' : 'Edit uvGO Settings'}</h2>
        <div className={classes.flexRow}>
          <div className={classes.flexWrap}>
            {this.groupInputControls.inputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  isExists={inputControl.isExists}
                  classes={{
                    flexRow: classes.fullFlex,
                  }}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                />
              ))}
          </div>
          <div>{this.uvGOSettingChildGrid}</div>
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(UvgoSettingEditor));
export { UvgoSettingEditor as PureUvgoSettingEditor };
