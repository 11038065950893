import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IGroupInputControls, IViewInputControl } from '@wings-shared/form-controls';
import { NavigateFunction } from 'react-router';
import { BlobModel, FeatureNoteModel, FeatureNoteStore } from '../../../Shared';
import { styles } from './FeatureNoteEditor.styles';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { AlertStore } from '@uvgo-shared/alert';
import { fields } from './Fields';
import { finalize, takeUntil } from 'rxjs/operators';
import classNames from 'classnames';
import FeatureNoteBlob from '../FeatureNoteBlob/FeatureNoteBlob';
import { forkJoin } from 'rxjs';
import {
  DATE_FORMAT,
  IClasses,
  IOptionValue,
  ISelectOption,
  UIStore,
  withRouter,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { DetailsEditorWrapper, EditSaveButtons } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  featureNoteStore?: FeatureNoteStore;
  viewMode?: VIEW_MODE;
  params?: { id: string };
  navigate?: NavigateFunction;
}

@inject('featureNoteStore')
@observer
class FeatureNoteEditor extends BaseUpsertComponent<Props, FeatureNoteModel> {
  @observable private featureNote: FeatureNoteModel = new FeatureNoteModel({ id: '' });

  constructor(p: Props) {
    super(p, fields);
    this.viewMode = VIEW_MODE.EDIT;
  }

  /* istanbul ignore next */
  componentDidMount() {
    if (!this.featureNoteId) {
      this.navigateToFeatureNotes();
      return;
    }
    const { featureNoteStore } = this.props;
    UIStore.setPageLoader(true);
    forkJoin([ featureNoteStore?.loadFeatureNoteById(this.featureNoteId), featureNoteStore?.getFeatureNotes() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ featureNote ]: FeatureNoteModel[]) => {
        this.featureNote = new FeatureNoteModel(featureNote);
        this.setFormValues(this.featureNote);
      });
  }

  /* istanbul ignore next */
  private updateFeatureNote(): void {
    const formValues: FeatureNoteModel = this.form.values();
    const featureNote = new FeatureNoteModel({ ...this.featureNote, ...formValues });
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.updateFeatureNote(featureNote)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => this.navigateToFeatureNotes(),
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private get loadCategories(): ISelectOption[] {
    const featureNoteStore = this.props.featureNoteStore as FeatureNoteStore;
    return featureNoteStore?.featureNotes
      .map(item => item.category?.value as string)
      .filter((value, index, self) => value && self.indexOf(value) === index)
      .map(item => ({ label: item, value: item }));
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'FeatureNote',
      inputControls: [
        {
          fieldKey: 'startDate',
          type: EDITOR_TYPES.DATE_TIME,
          dateTimeFormat: DATE_FORMAT.GRID_DISPLAY,
          allowKeyboardInput: false,
        },
        {
          fieldKey: 'title',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'category',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.loadCategories,
          freeSolo: true,
          isExists: Boolean(this.customErrorMessage),
          customErrorMessage: this.customErrorMessage,
        },
        {
          fieldKey: 'releaseVersion',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'isInternal',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'isPublished',
          type: EDITOR_TYPES.CHECKBOX,
          isDisabled: true,
        },
        {
          fieldKey: 'isArchived',
          type: EDITOR_TYPES.CHECKBOX,
          isDisabled: true,
        },
        {
          fieldKey: 'blobUrls',
          type: EDITOR_TYPES.CUSTOM_COMPONENT,
        },
        {
          fieldKey: 'message',
          type: EDITOR_TYPES.MARKDOWN_EDITOR,
          isFullFlex: true,
        },
      ],
    };
  }

  private get customErrorMessage(): string {
    return this.getField('category').value?.label?.length > 100 ? 'The Category field must be between 1 and 100.' : '';
  }

  private get featureNoteId(): string | null {
    return this.props.params?.id ? this.props.params?.id || '' : null;
  }

  private navigateToFeatureNotes(): void {
    this.props.navigate && this.props.navigate('/general/feature-notes');
  }

  private onAction(action: GRID_ACTIONS): void {
    if (action === GRID_ACTIONS.CANCEL) {
      this.navigateToFeatureNotes();
      return;
    }
    this.updateFeatureNote();
  }

  private get hasError(): boolean {
    return this.form.hasError || UIStore.pageLoading || Boolean(this.customErrorMessage);
  }

  private get headerActions(): ReactNode {
    return (
      <EditSaveButtons
        disabled={this.hasError}
        hasEditPermission={true}
        isEditMode={this.isEditable}
        onAction={action => this.onAction(action)}
      />
    );
  }

  private get blobData(): BlobModel[] {
    return this.getField('blobUrls')?.values() || [];
  }

  private get blobGrid(): ReactNode {
    const { featureNoteStore } = this.props;
    return (
      <FeatureNoteBlob
        featureNoteId={this.featureNoteId}
        rowData={this.blobData}
        featureNoteStore={featureNoteStore}
        onDataUpdate={(blobs: BlobModel[]) => this.getField('blobUrls').set(blobs)}
      />
    );
  }

  public render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <h2>Edit Feature Note</h2>
        <div className={classes.flexRow}>
          <div className={classes.flexWrap}>
            {this.groupInputControls.inputControls.map((inputControl: IViewInputControl, index: number) => {
              if (inputControl.fieldKey === 'blobUrls') {
                return (
                  <div key={index} className={classes.blobGrid}>
                    {' '}
                    {this.blobGrid}{' '}
                  </div>
                );
              }
              return (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  classes={{
                    flexRow: classNames({
                      [classes.inputControl]: true,
                      [classes.isFullFlex]: inputControl.isFullFlex,
                    }),
                  }}
                  onValueChange={(option: IOptionValue, _: string) =>
                    this.onValueChange(option, inputControl.fieldKey || '')
                  }
                />
              );
            })}
          </div>
        </div>
      </DetailsEditorWrapper>
    );
  }
}
export default withRouter(withStyles(styles)(FeatureNoteEditor));
export { FeatureNoteEditor as PureFeatureNoteEditor };
