import React, { ReactNode } from 'react';
import { withStyles, Theme } from '@material-ui/core';
import { CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';
import { action } from 'mobx';
import { ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { BlobModel, FeatureNoteModel, FeatureNoteStore } from '../../../Shared';
import { styles } from './FeatureNoteBlob.styles';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { IClasses, UIStore, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { ChildGridWrapper, ConfirmDialog, CollapsibleWithButton, ImportDialog } from '@wings-shared/layout';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  featureNoteId: string;
  rowData: BlobModel[];
  onDataUpdate?: (blobs: BlobModel[]) => void;
  featureNoteStore?: FeatureNoteStore;
};

@inject('featureNoteStore')
@observer
class FeatureNoteBlob extends BaseGrid<Props, BlobModel> {
  constructor(props) {
    super(props);
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Url',
      field: 'url',
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: (node: RowNode) => [
          {
            title: `Copy as ${node?.data?.name.split('.').pop() === 'gif' ? 'GIF' : 'Image'}`,
            isHidden: false,
            action: GRID_ACTIONS.SAVE,
          },
          {
            title: 'Delete',
            isHidden: false,
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        minWidth: 150,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
      },
    };
  }

  /* istanbul ignore next */
  private uploadBlob(file: File): void {
    const { featureNoteId } = this.props;
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.uploadBlob(featureNoteId, file)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: (response: FeatureNoteModel) => {
          if (response) {
            this.props.onDataUpdate && this.props.onDataUpdate(response.blobUrls);
          }
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private removeBlob(rowIndex: number): void {
    const { featureNoteId, featureNoteStore } = this.props;
    const blob: BlobModel = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    featureNoteStore
      ?.removeBlob(featureNoteId, blob.url)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this._removeTableItems([ blob ]);
            this.props.onDataUpdate && this.props.onDataUpdate(this._getAllTableRows());
          }
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  private openUploadBlobModal(): void {
    ModalStore.open(
      <ImportDialog
        title="Select Media"
        btnText="Upload"
        fileType="jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF"
        isLoading={() => this.isLoading}
        onUploadFile={file => this.uploadBlob(file)}
      />
    );
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveBlob(rowIndex);
        break;
      case GRID_ACTIONS.SAVE:
        this.copyBlobUrl(rowIndex);
        break;
    }
  }

  private copyBlobUrl(rowIndex: number) {
    const blob: BlobModel = this._getTableItem(rowIndex);
    navigator.clipboard.writeText(`<img src="${blob.url}" alt="${blob.name}" width="800"/>`);
    AlertStore.info('Copied.!!');
  }

  @action
  private confirmRemoveBlob(rowIndex: number): void {
    const model: BlobModel = this._getTableItem(rowIndex);
    if (model.id === '') {
      this._removeTableItems([ model ]);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Deleting this media doesn't delete the media tagged in content.
         Are you sure you want to remove this media?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.removeBlob(rowIndex)}
      />
    );
  }

  render(): ReactNode {
    const { rowData } = this.props;
    return (
      <CollapsibleWithButton
        title="Media"
        buttonText="Upload Media"
        isButtonDisabled={this.isProcessing}
        onButtonClick={() => this.openUploadBlobModal()}
      >
        <ChildGridWrapper>
          <CustomAgGridReact
            isRowEditing={this.isRowEditing}
            rowData={rowData}
            gridOptions={this.gridOptions}
            disablePagination={this.isRowEditing}
          />
        </ChildGridWrapper>
      </CollapsibleWithButton>
    );
  }
}
export default withStyles(styles)(FeatureNoteBlob);
