import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { FormControlLabel, withStyles, Checkbox } from '@material-ui/core';
import { fields } from './Fields';
import { action, observable } from 'mobx';
import { styles } from './UpsertRetailData.style';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { IAPIRetailDataOptionsResponse, RetailDataOptions, RetailDataStore } from '../../../Shared';
import { IClasses, IOptionValue, UIStore, Utilities, ViewPermission } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl, IGroupInputControls, IViewInputControl } from '@wings-shared/form-controls';

type Props = {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  retailDataStore?: RetailDataStore;
  upsertRetailData: (request: IAPIRetailDataOptionsResponse) => void;
  retailData?: RetailDataOptions;
};

@inject('retailDataStore')
@observer
class UpsertRetailData extends BaseUpsertComponent<Props, RetailDataOptions> {
  @observable private isAllSelected: boolean = false;
  constructor(props: Props) {
    super(props, fields);
  }

  /* istanbul ignore next */
  componentDidMount() {
    const retailData = this.props.retailData as RetailDataOptions;
    this.setFormValues(retailData);
  }

  private get hasError(): boolean {
    return UIStore.pageLoading || !Object.values(this.form.values()).some(x => x);
  }

  @action
  private setSelectAll(isChecked: boolean) {
    Object.keys(fields).forEach(key => this.getField(key).set(isChecked));
    this.isAllSelected = isChecked;
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
    this.isAllSelected = Object.keys(fields).every(key => this.getField(key).value);
  }

  @action
  private upsertRetailData(): void {
    this.props.upsertRetailData(this.form.values());
  }

  /* istanbul ignore next */
  private get groupInputControlsInactive(): IGroupInputControls {
    return {
      title: 'RetailData',
      inputControls: [
        {
          fieldKey: 'airport1Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airport2Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airport3Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airport4Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airportFlightpakInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway1Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway2Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway3Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway4Inactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'fboInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'fboServicesInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'hotelInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'attendenceInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'remarksInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'cateringInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'transportationInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'securityInactive',
          type: EDITOR_TYPES.CHECKBOX,
        },
      ],
    };
  }
  /* istanbul ignore next */
  private get groupInputControlsActive(): IGroupInputControls {
    return {
      title: 'RetailData',
      inputControls: [
        {
          fieldKey: 'airport1Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airport2Active',
          type: EDITOR_TYPES.CHECKBOX,
          autoSelect: false,
        },
        {
          fieldKey: 'airport3Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airport4Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'airportFlightpakActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway1Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway2Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway3Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'runway4Active',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'fboActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'fboServicesActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'hotelActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'attendenceActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'remarksActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'cateringActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'transportationActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'securityActive',
          type: EDITOR_TYPES.CHECKBOX,
        },
      ],
    };
  }

  /* istanbul ignore next */
  private get dialogContent(): ReactNode {
    const { classes, viewMode } = this.props as Required<Props>;
    return (
      <>
        {this.loader.spinner}
        <div className={Utilities.isEqual(viewMode, VIEW_MODE.EDIT) ? classes.modalDetailEdit : classes.modalDetail}>
          <ViewPermission hasPermission={!Utilities.isEqual(viewMode, VIEW_MODE.EDIT)}>
            <div className={classes.checkSection}>
              <FormControlLabel
                control={<Checkbox checked={this.isAllSelected} onChange={e => this.setSelectAll(e.target.checked)} />}
                label="Select All"
              />
            </div>
          </ViewPermission>
          <div className={classes.modalDetailSection}>
            {this.groupInputControlsActive.inputControls.map((inputControl: IViewInputControl, index: number) => (
              <ViewInputControl
                {...inputControl}
                key={index}
                classes={{
                  flexRow: classes.fullFlex,
                }}
                field={this.getField(inputControl.fieldKey || '')}
                isEditable={this.isEditable}
                onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
              />
            ))}
          </div>
          <hr />
          <div className={classes.modalDetailSection}>
            {this.groupInputControlsInactive.inputControls.map((inputControl: IViewInputControl, index: number) => (
              <ViewInputControl
                {...inputControl}
                key={index}
                classes={{
                  flexRow: classes.fullFlex,
                }}
                field={this.getField(inputControl.fieldKey || '')}
                isEditable={this.isEditable}
                onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
              />
            ))}
          </div>
          <div className={classes.btnContainer}>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.upsertRetailData()}
              disabled={this.hasError}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <Dialog
        title={`${this.props.viewMode === VIEW_MODE.NEW ? 'Create Retail Data Job' : 'Selections for Retail Data Job'}`}
        open={true}
        classes={{
          dialogWrapper: classes.modalRoot,
          paperSize: classes.userMappedWidth,
          header: classes.headerWrapper,
          content: classes.content,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
      />
    );
  }
}

export default withStyles(styles)(UpsertRetailData);
