import {
  baseApiPath,
  BaseStore,
  HttpClient,
} from '@wings/shared';
import { Observable } from 'rxjs';
import { apiUrls } from './API.url';
import { map } from 'rxjs/operators';
import { IAPILogResponse } from '../Interfaces';
import { action, observable } from 'mobx';
import { LogModel } from '../Models';
import { EnvironmentVarsStore, ENVIRONMENT_VARS } from '@wings-shared/env-store';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';

const env = new EnvironmentVarsStore();
const headers = {
  'Ocp-Apim-Subscription-Key': env.getVar(ENVIRONMENT_VARS.USER_MANAGEMENT_SUBSCRIPTION_KEY),
  'Ocp-Apim-Trace': true,
};

export class LogStore extends BaseStore {
  @observable public logsFilter: string = 'ALL';

  public setLogsFilter(filter: string) {
    this.logsFilter = filter;
  }

  /* istanbul ignore next */
  public getLog(request?: IAPIGridRequest): Observable<IAPIPageResponse<LogModel>> {
    const params = Utilities.buildParamString({
      query: request?.searchCollection || '',
      page: request?.pageNumber || 1,
      size: request?.pageSize || 30,
      sort: 'Timestamp',
      direction: 1,
      userGuid: request.q,
      ...request,
    });
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.userManagement, headers });
    return http.get<IAPIPageResponse<IAPILogResponse>>(`${apiUrls.logs}?${params}`).pipe(
      Logger.observableCatchError,
      map((response) => {
        return {
          totalNumberOfRecords: response.Data.TotalNumberOfRecords,
          pageNumber: response.Data.PageNumber,
          pageSize: response?.Data.PageSize,
          results: Utilities.customArraySort<LogModel>(LogModel.deserializeList(response.Data.Results), 'Timestamp'),
        }
      })
    )
  }

}