import React, { ReactNode } from 'react';
import { action } from 'mobx';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { styles } from './Sessions.styles';
import { withStyles, Theme, Typography } from '@material-ui/core';
import { finalize } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ColDef, GridOptions, RowNode, ValueFormatterParams } from 'ag-grid-community';
import { SESSION_FILTER } from '../Shared/Enums';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import moment from 'moment';
import PeopleIcon from '@material-ui/icons/People';
import { SessionStore, UserResponseModel, UserSessionModel } from '../Shared';
import { SearchHeader } from '@wings-shared/form-controls';
import { DATE_FORMAT, IClasses, UIStore, Utilities, IBaseGridFilterSetup } from '@wings-shared/core';
import {
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
} from '@wings-shared/custom-ag-grid';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  sessionStore?: SessionStore;
  user?: UserResponseModel;
  openSessionDeleteConfirmation?: (session: UserSessionModel, user: UserResponseModel) => void;
};

const filtersSetup: IBaseGridFilterSetup<SESSION_FILTER> = {
  defaultPlaceHolder: 'Search Session',
  filterTypesOptions: Object.values(SESSION_FILTER),
  defaultFilterType: SESSION_FILTER.ALL,
};
@inject('sessionStore')
@observer
class Sessions extends BaseGrid<Props, UserSessionModel> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  @action
  loadInitialData() {
    const { user, sessionStore } = this.props;
    sessionStore
      .loadSessionUsers(user.oktaUserId)
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe((data: UserSessionModel[]) => (this.data = data));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Connection Id',
      field: 'connectionId',
    },
    {
      headerName: 'Client Name',
      field: 'clientName',
    },
    {
      headerName: 'Client Id',
      field: 'clientId',
    },
    {
      headerName: 'Connection Timestamp',
      field: 'timestamp',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(moment.utc(value).format(DATE_FORMAT.API_FORMAT), DATE_FORMAT.SDT_DST_FORMAT),
    },
    {
      headerName: 'IP Address',
      field: 'ipAddress',
    },
    {
      headerName: 'User Agent',
      field: 'userAgent',
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'viewRenderer',
      filter: false,
      minWidth: 100,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode) => this.viewRenderer(node.data),
      },
    },
  ];

  private viewRenderer(rowData: UserSessionModel): ReactNode {
    return (
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={() => this.props.openSessionDeleteConfirmation(rowData, this.props.user)}
      >
        Delete
      </PrimaryButton>
    );
  }

  private get gridOptions(): GridOptions {
    return {
      ...this._gridOptionsBase({
        context: this,
        columnDefs: this.columnDefs,
        isEditable: true,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => this.hasError,
        },
      }),
      doesExternalFilterPass: node => {
        const { clientName, ipAddress } = node.data as UserSessionModel;
        return this._isFilterPass({
          [SESSION_FILTER.ALL]: [ clientName.toString(), ipAddress.toString() ],
          [SESSION_FILTER.CLIENTNAME]: clientName.toString(),
          [SESSION_FILTER.IPADDRESS]: ipAddress.toString(),
        });
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customHeader: AgGridGroupHeader,
        viewRenderer: AgGridViewRenderer,
      },
      pagination: true,
    };
  }

  render(): ReactNode {
    const { classes } = this.props;
    return (
      <Dialog
        title=""
        open={true}
        classes={{
          dialogWrapper: classes.modalRoot,
          paperSize: classes.paperSize,
          header: classes.headerWrapper,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
      />
    );
  }

  private get dialogContent(): ReactNode {
    const { classes, user } = this.props;
    return (
      <>
        <div className={classes.subSection}>
          <PeopleIcon className={classes.icon} />
          <Typography component="h3" className={classes.heading}>
            Sessions : {user.email}
          </Typography>
        </div>
        <div className={classes.headerContainer}>
          <div>
            <SearchHeader
              searchPlaceHolder={this.searchPlaceHolder}
              searchTypeValue={this.selectedOption}
              searchTypeOptions={this._selectOptions}
              onSearchTypeChange={option => this._setSelectedOption(option as SESSION_FILTER)}
              onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
            />
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact gridOptions={this.gridOptions} rowData={this.data} />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Sessions);
