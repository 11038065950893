import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, BaseGrid, AgGridActions, AgGridGroupHeader } from '@wings-shared/custom-ag-grid';
import { action } from 'mobx';
import { styles } from './Fuel.styles';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { withStyles, Typography, Theme } from '@material-ui/core';
import { finalize, takeUntil, switchMap } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { inject, observer } from 'mobx-react';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import { PrimaryButton } from '@uvgo-shared/buttons';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ColDef, GridOptions, RowNode, ValueFormatterParams } from 'ag-grid-community';
import { FuelModel } from '../Shared/Models/Fuel.model';
import { FUEL_FILTERS, FuelStore, IAPIUpsertFuelRequest } from '../Shared';
import UpsertFuel from './Components/UpsertFuel/UpsertFuel';
import {
  GridPagination,
  IAPIGridRequest,
  IClasses,
  UIStore,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { ConfirmDialog } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  fuelStore?: FuelStore;
};

const filtersSetup: IBaseGridFilterSetup<FUEL_FILTERS> = {
  defaultPlaceHolder: 'Search Fuel',
  filterTypesOptions: Object.values(FUEL_FILTERS),
  defaultFilterType: FUEL_FILTERS.ALL,
};
@inject('fuelStore')
@observer
class Fuel extends BaseGrid<Props, FuelModel, FUEL_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData('enter');
  }

  /* istanbul ignore next */
  @action
  private loadInitialData(key: string, pageRequest?: IAPIGridRequest): void {
    if (key === 'enter' || (key === 'backspace' && !this.searchValue)) {
      const request: IAPIGridRequest = {
        ...pageRequest,
        searchCollection: this.searchValue as string,
      };
      UIStore.setPageLoader(true);
      this.props.fuelStore
        ?.getFuel(request)
        .pipe(finalize(() => UIStore.setPageLoader(false)))
        .subscribe(response => {
          this.data = response.results;
          this.pagination = new GridPagination({ ...response });
        });
    }
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'UWA Customer No',
      field: 'uwaCustomerId',
    },
    {
      headerName: 'WFC No',
      field: 'wfsCustomerId',
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
    },
    {
      headerName: 'Archived',
      field: 'archived',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        if (value === undefined) {
          return '';
        }
        return value ? 'Yes' : 'No';
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      minWidth: 160,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          { title: 'Edit', action: GRID_ACTIONS.EDIT },
          { title: 'Delete', action: GRID_ACTIONS.DELETE },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number, node: RowNode) => {
          this.gridActions(action, rowIndex);
        },
      },
    },
  ];

  /* istanbul ignore next */
  @action
  private deleteFuel(fuel: FuelModel): void {
    const { fuelStore } = this.props;
    UIStore.setPageLoader(true);
    fuelStore
      ?.deleteFuel(fuel.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe(
        () => {
          this._removeTableItems([ fuel ]);
          AlertStore.info('Fuel deleted successfully');
        },
        (error: AxiosError) => AlertStore.info(error.message)
      );
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }

    const fuel = this._getTableItem(rowIndex);
    if (gridAction === GRID_ACTIONS.EDIT) {
      this.openFuelDialog(VIEW_MODE.EDIT, fuel);
    }

    if (gridAction === GRID_ACTIONS.DELETE) {
      ModalStore.open(
        <ConfirmDialog
          title="Confirm Delete"
          message="Are you sure you want to delete this fuel mapping?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => this.deleteFuel(fuel)}
        />
      );
    }
  }

  /* istanbul ignore next */
  @action
  private upsertFuel(upsertFuelRequest: IAPIUpsertFuelRequest): void {
    const { fuelStore } = this.props;
    UIStore.setPageLoader(true);
    fuelStore
      ?.upsertFuel(upsertFuelRequest)
      .pipe(
        switchMap(() => fuelStore.getFuel()),
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: response => (this.data = response.results),
        error: (error: AxiosError) => AlertStore.info(error.message),
      });
  }

  /* istanbul ignore next */
  private openFuelDialog(mode: VIEW_MODE, fuel?: FuelModel): void {
    const { fuelStore } = this.props;
    ModalStore.open(
      <UpsertFuel
        fuelStore={fuelStore}
        viewMode={mode}
        fuel={fuel}
        upsertFuel={(upsertFuelRequest: IAPIUpsertFuelRequest) => this.upsertFuel(upsertFuelRequest)}
      />
    );
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    return {
      ...this._gridOptionsBase({
        context: this,
        columnDefs: this.columnDefs,
        isEditable: false,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => this.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      isExternalFilterPresent: () => false,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customHeader: AgGridGroupHeader,
      },
      pagination: false,
    };
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.subSection}>
            <LocalGasStationIcon className={classes.icon} />
            <Typography component="h3" className={classes.heading}>
              Fuel Mappings
            </Typography>
          </div>
          <div>
            <SearchHeader
              searchPlaceHolder={this.searchPlaceHolder}
              searchTypeValue={this.selectedOption}
              searchTypeOptions={this._selectOptions}
              onSearchTypeChange={option => this._setSelectedOption(option as FUEL_FILTERS)}
              onKeyUp={key => this.loadInitialData(key)}
              onSearch={(searchValue: string) => {
                this.searchValue = searchValue;
              }}
              isHideSearchSelectControl={true}
              onClear={() => {
                this._setSearchValue('');
                this.loadInitialData('enter');
              }}
            />
          </div>
          <div>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.openFuelDialog(VIEW_MODE.NEW, new FuelModel())}
              startIcon={<AddIcon />}
            >
              Add Fuel Mappings
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact
              rowData={this.data}
              gridOptions={this.gridOptions}
              serverPagination={true}
              paginationData={this.pagination}
              onPaginationChange={request => this.loadInitialData('enter', request)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Fuel);
