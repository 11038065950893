import React, { FC, ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { styles } from './FilterLogs.style';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { LogStore } from '../../../Shared';
import { SelectInputControl } from '@wings-shared/form-controls';
import { SelectOption } from '@wings-shared/core';

interface Props {
  viewMode?: VIEW_MODE;
  logStore?: LogStore;
  onSetClick: ({ status }) => void;
}

const FilterLogs: FC<Props> = ({ ...props }: Props) => {

  const _logStore = props.logStore as LogStore;
  const classes: Record<string, string> = styles();
  const categoryList: SelectOption[] = [
    new SelectOption({ name: 'ALL', value: 'ALL' }),
    new SelectOption({ name: 'SUCCESS', value: 'SUCCESS' }),
    new SelectOption({ name: 'FAILURE', value: 'FAILURE' }),
  ];

  const handleReset = () => {
    _logStore?.setLogsFilter('ALL');
    props.onSetClick({ status: 'ALL' });
  }

  /* istanbul ignore next */
  const dialogContent = (): ReactNode => {
    return (
      <>
        <div className={classes.modalDetail}>
          <div className={classes.flexRow}>
            <Typography variant="h6" className={classes.subTitle}>
              Status
            </Typography>
            <SelectInputControl
              containerClass={classes.dropDown}
              value={_logStore?.logsFilter}
              selectOptions={categoryList}
              onOptionChange={item => _logStore?.setLogsFilter(item)}
            />
          </div>
          <div className={classes.btnContainer}>
            <div className={classes.btnSection}>
              <PrimaryButton variant="contained" color="primary" onClick={handleReset}>
                Reset
              </PrimaryButton>
            </div>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() =>
                props.onSetClick({ status: _logStore?.logsFilter })
              }
            >
              Set
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }

  return (
    <Dialog
      title="Filter Logs"
      open={true}
      classes={{
        dialogWrapper: classes.modalRoot,
        paperSize: classes.userMappedWidth,
        header: classes.headerWrapper,
        content: classes.content,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={dialogContent}
    />
  );
}

export default inject('logStore')(observer(FilterLogs));
