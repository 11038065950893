import React, { ChangeEvent, ReactNode } from 'react';
import { withStyles, Typography, Theme, FormControlLabel, Checkbox } from '@material-ui/core';
import { VIEW_MODE } from '@wings/shared';
import { action, observable } from 'mobx';
import { ColDef, GridOptions, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { FeatureNoteModel, FeatureNoteStore, FEATURE_NOTE_FILTERS } from '../Shared';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import NotesIcon from '@material-ui/icons/Notes';
import { PrimaryButton } from '@uvgo-shared/buttons';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { AlertStore } from '@uvgo-shared/alert';
import { AxiosError } from 'axios';
import { styles } from './FeatureNote.styles';
import { FeatureNoteDialog, MarkdownPreviewControl } from './Components';
import { NavigateFunction } from 'react-router';
import { Dialog } from '@uvgo-shared/dialog';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import {
  DATE_FORMAT,
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  withRouter,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { ConfirmDialog } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridCheckBox,
} from '@wings-shared/custom-ag-grid';
import FeatureNoteDialogV2 from './Components/FeatureNoteDialog/FeatureNoteDialogV2';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  featureNoteStore?: FeatureNoteStore;
  navigate?: NavigateFunction;
};

const filtersSetup: IBaseGridFilterSetup<FEATURE_NOTE_FILTERS> = {
  defaultPlaceHolder: 'Search by Start Date',
  filterTypesOptions: Object.values(FEATURE_NOTE_FILTERS),
  defaultFilterType: FEATURE_NOTE_FILTERS.START_DATE,
};

@inject('featureNoteStore')
@observer
class FeatureNote extends BaseGrid<Props, FeatureNoteModel> {
  @observable private includeArchive: boolean = false;

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  @action
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.getFeatureNotes(this.includeArchive)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(featureNotes => (this.data = featureNotes));
  }

  /* istanbul ignore next */
  @action
  private removeFeatureNote(rowIndex: number): void {
    ModalStore.close();
    const featureNote: FeatureNoteModel = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.removeFeatureNote(featureNote)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this._removeTableItems([ featureNote ]);
            this.data = this._getAllTableRows();
          }
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private addFeatureNote(featureNote: FeatureNoteModel): void {
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.addFeatureNote(featureNote)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: (featureNote: FeatureNoteModel) =>
          this.props.navigate &&
          this.props.navigate(`/general/feature-notes/${featureNote?.id}/${VIEW_MODE.EDIT.toLowerCase()}`),
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private updateFeatureNote(rowIndex: number, featureNote: FeatureNoteModel): void {
    UIStore.setPageLoader(true);
    this.props.featureNoteStore
      ?.updateFeatureNote(featureNote)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: response => {
          if (response) {
            if (featureNote.isArchived && !this.includeArchive) {
              this._removeTableItems([ featureNote ]);
              return;
            }
            this._updateTableItem(rowIndex, featureNote);
            this.loadInitialData();
          }
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Start Date',
      field: 'startDate',
      valueFormatter: ({ value }: ValueFormatterParams) => Utilities.getformattedDate(value, DATE_FORMAT.GRID_DISPLAY),
    },
    {
      headerName: 'Title',
      field: 'title',
    },
    {
      headerName: 'Category',
      field: 'category',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Version',
      field: 'releaseVersion',
    },
    {
      headerName: 'Internal',
      field: 'isInternal',
      filter: true,
      filterParams: { applyMiniFilterWhileTyping: true },
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Published',
      field: 'isPublished',
      filter: true,
      filterParams: { applyMiniFilterWhileTyping: true },
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Archived',
      field: 'isArchived',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: {
        readOnly: true,
      },
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      valueFormatter: ({ value }: ValueFormatterParams) => Utilities.getformattedDate(value, DATE_FORMAT.GRID_DISPLAY),
    },
    {
      headerName: 'Preview',
      field: 'message',
      cellRenderer: 'viewRenderer',
      minWidth: 100,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, { data }: RowNode) =>
          data?.message ? (
            <div className={this.props.classes?.center}>
              <IconButton edge="end" onClick={() => this.previewMarkdown(data.message)}>
                <RemoveRedEye fontSize="small" />
              </IconButton>
            </div>
          ) : (
            ''
          ),
      },
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: (node: RowNode) => [
          {
            title: 'Edit',
            isHidden: false,
            action: GRID_ACTIONS.EDIT,
            to: node => `/general/feature-notes/${node.data?.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Publish',
            isHidden: Boolean(node.data.isPublished) || Boolean(node.data.isArchived),
            action: GRID_ACTIONS.PUBLISH,
          },
          {
            title: 'Archive',
            isHidden: Boolean(node.data.isArchived),
            action: GRID_ACTIONS.ARCHIVE,
          },
          {
            title: 'UnArchive',
            isHidden: !Boolean(node.data.isArchived),
            action: GRID_ACTIONS.UNARCHIVE,
          },
          {
            title: 'Delete',
            isHidden: false,
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
    });

    return {
      ...baseOptions,
      onFilterModified: (filterModified: any) => this.onGridApiFilterModified(filterModified),
      doesExternalFilterPass: node => {
        const { id, startDate, title, releaseVersion, category } = node.data as FeatureNoteModel;
        return (
          !id ||
          this._isFilterPass({
            [FEATURE_NOTE_FILTERS.START_DATE]: Utilities.getformattedDate(startDate, DATE_FORMAT.GRID_DISPLAY),
            [FEATURE_NOTE_FILTERS.TITLE]: title,
            [FEATURE_NOTE_FILTERS.VERSION]: releaseVersion,
            [FEATURE_NOTE_FILTERS.CATEGORY]: category?.value as string,
          })
        );
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        minWidth: 150,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        checkBoxRenderer: AgGridCheckBox,
        viewRenderer: AgGridViewRenderer,
      },
    };
  }

  /* istanbul ignore next */
  private openAddFeatureNoteDialog(): void {
    ModalStore.open(
      <FeatureNoteDialogV2 featureNotes={this.data} addFeatureNote={featureNote => this.addFeatureNote(featureNote)} />
    );
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveFeatureNote(rowIndex);
        break;
      case GRID_ACTIONS.PUBLISH:
        this.confirmPublishFeatureNote(rowIndex);
        break;
      case GRID_ACTIONS.ARCHIVE:
        this.confirmArchiveFeatureNote(rowIndex);
        break;
      case GRID_ACTIONS.UNARCHIVE:
        this.confirmUnArchiveFeatureNote(rowIndex);
        break;
    }
  }

  private previewMarkdown(value: string): void {
    const classes = this.props.classes as IClasses;
    ModalStore.open(
      <Dialog
        title={'Markdown Preview'}
        open={true}
        onClose={() => ModalStore.close()}
        classes={{ paperSize: classes.paperSize }}
        dialogContent={() => (
          <div className={classes.root}>
            <MarkdownPreviewControl value={value} />
          </div>
        )}
      />
    );
  }

  @action
  private confirmRemoveFeatureNote(rowIndex: number): void {
    const model: FeatureNoteModel = this._getTableItem(rowIndex);
    if (model.id === '') {
      this._removeTableItems([ model ]);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this Feature Note?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.removeFeatureNote(rowIndex)}
      />
    );
  }

  @action
  private confirmPublishFeatureNote(rowIndex: number): void {
    const model: FeatureNoteModel = this._getTableItem(rowIndex);
    if (model.id === '') {
      this._removeTableItems([ model ]);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Publish"
        message="Are you sure you want to publish this Feature Note?"
        yesButton="Publish"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          model.isPublished = true;
          this.updateFeatureNote(rowIndex, model);
        }}
      />
    );
  }

  @action
  private confirmArchiveFeatureNote(rowIndex: number): void {
    const model: FeatureNoteModel = this._getTableItem(rowIndex);
    if (model.id === '') {
      this._removeTableItems([ model ]);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Archive"
        message="Are you sure you want to archive this Feature Note?"
        yesButton="Archive"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          model.isArchived = true;
          this.updateFeatureNote(rowIndex, model);
        }}
      />
    );
  }

  @action
  private confirmUnArchiveFeatureNote(rowIndex: number): void {
    const model: FeatureNoteModel = this._getTableItem(rowIndex);
    if (model.id === '') {
      this._removeTableItems([ model ]);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm UnArchive"
        message="Are you sure you want to unarchive this Feature Note?"
        yesButton="UnArchive"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          model.isArchived = false;
          this.updateFeatureNote(rowIndex, model);
        }}
      />
    );
  }

  @action
  private includeArchivedChange(event: ChangeEvent<HTMLInputElement>): void {
    this.includeArchive = event.target.checked;
    this.loadInitialData();
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.subSection}>
            <NotesIcon className={classes.icon} />
            <Typography component="h3" className={classes.heading}>
              Feature Notes
            </Typography>
          </div>
          <div className={classes.searchContainer}>
            <SearchHeader
              searchPlaceHolder={this.searchPlaceHolder}
              searchTypeValue={this.selectedOption}
              searchTypeOptions={this._selectOptions}
              onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as FEATURE_NOTE_FILTERS)}
              onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
            />
            <FormControlLabel
              control={<Checkbox onChange={e => this.includeArchivedChange(e)} />}
              label="Include Archived"
            />
          </div>
          <div>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.openAddFeatureNoteDialog()}
              startIcon={<AddIcon />}
            >
              Add Feature Note
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact gridOptions={this.gridOptions} rowData={this.data} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(FeatureNote));
export { FeatureNote as PureFeatureNote };
