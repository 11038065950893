import { Button, withStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IGroupInputControls, IViewInputControl } from '@wings-shared/form-controls';
import { styles } from './OptionField.style';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { UvgoSettingsStore, SettingOptionsModel, JobTypeOptions, JOB_TYPE, BoolTypeOptions } from '../../../Shared';
import { fields } from './Fields';
import classNames from 'classnames';
import { FieldTypeModel } from '@wings/notifications/src/Modules';
import { action } from 'mobx';
import { IClasses, IOptionValue, ISelectOption, Utilities } from '@wings-shared/core';

type Props = {
  classes: IClasses;
  title: string;
  optionField?: SettingOptionsModel;
  viewMode?: VIEW_MODE;
  optionsField?: SettingOptionsModel[];
  upsertOptionField: (optionField: SettingOptionsModel) => void;
  uvgoSettingsStore?: UvgoSettingsStore;
};

@observer
class OptionField extends BaseUpsertComponent<Props, SettingOptionsModel> {
  constructor(p: Props) {
    super(p, fields);
  }

  componentDidMount() {   
    const optionField = this.props.optionField as SettingOptionsModel;
    this.setFormValues(optionField);
    this.setValueFieldRules((optionField.type as ISelectOption)?.label);
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'OptionField',
      inputControls: [
        {
          fieldKey: 'keyName',
          label: 'Key',
          type: EDITOR_TYPES.TEXT_FIELD,
          isExists: this.isExists,
        },
        {
          fieldKey: 'type',
          label: 'Type',
          type: EDITOR_TYPES.DROPDOWN,
          options: JobTypeOptions,
        },
        {
          fieldKey: 'value',
          label: 'Value',
          type: EDITOR_TYPES.TEXT_FIELD,
          isHidden: this.isBoolValue,
        },
        {
          fieldKey: 'value',
          label: 'Value',
          type: EDITOR_TYPES.DROPDOWN,
          options: BoolTypeOptions,
          isHidden: !this.isBoolValue,
        },
      ],
    };
  }

  private get isBoolValue(): boolean {
    return this.getField('type')?.value?.value === JOB_TYPE.BOOL;
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    if (value && Utilities.isEqual(fieldKey, 'type')) {
      this.getField('value').set('');
      this.setValueFieldRules((value as ISelectOption).label);
    }
    this.getField(fieldKey).set(value);
  }

  /* istanbul ignore next */
  private setValueFieldRules(value: string): void {
    if (Utilities.isEqual(value, JOB_TYPE.INT)) {
      this.getField('value').set('rules', 'required:true|integer|min:1|max:999999999');
    }
    if (Utilities.isEqual(value, JOB_TYPE.STRING)) {
      this.getField('value').set('rules', 'required:true|string|between:1,100');
    }
    if (Utilities.isEqual(value, JOB_TYPE.BOOL)) {
      this.getField('value').set('rules', 'required');
    }
  }

  private get isExists(): boolean {
    const { optionField, optionsField } = this.props;
    const keyName = this.getField('keyName').value;
    if(!optionsField?.length){
      return false
    }
    return optionsField.some(
      t => Utilities.isEqual(t.keyName, keyName) && !Utilities.isEqual(t.keyName, optionField?.keyName || '')
    );
  }

  private get dialogContent(): ReactNode {
    const { classes, upsertOptionField } = this.props;
    return (
      <div>
        <div>
          {this.groupInputControls.inputControls
            .filter(inputControl => !inputControl.isHidden)
            .map((inputControl: IViewInputControl, index: number) => {
              return (
                <>
                  <div className={classes.formatContainer}>
                    <div>
                      <ViewInputControl
                        {...inputControl}
                        key={index}
                        isExists={inputControl.isExists}
                        classes={{
                          flexRow: classNames([ classes.inputControl ]),
                        }}
                        field={this.getField(inputControl.fieldKey || '')}
                        isEditable={this.isEditable}
                        onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          <div className={classes.btnContainer}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={this.form.hasError || this.isExists || !this.form.changed}
              onClick={() => {
                const formValues = this.form.values()
                const { fieldType } = this.form.values();
                const model = new SettingOptionsModel({
                  ...this.props.optionField,
                  ...this.form.values(),
                  fieldType: new FieldTypeModel(fieldType),
                  value: typeof formValues?.value == 'object' ? formValues?.value?.value : formValues?.value,
                });
                upsertOptionField(model);
              }}
            >
              {this.props.viewMode === VIEW_MODE.NEW ? 'Create' : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  public render(): ReactNode {
    const { classes, title } = this.props;
    return (
      <Dialog
        title={title}
        open={true}
        classes={{
          dialogWrapper: classes.modalRoot,
          paperSize: classes.dialogWidth,
          header: classes.headerWrapper,
          content: classes.content,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
      />
    );
  }
}
export default withStyles(styles)(OptionField);
export { OptionField as PureOptionField };
