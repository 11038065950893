import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { withStyles } from '@material-ui/core';
import { FuelModel } from '../../../Shared/Models/Fuel.model';
import { fields } from './Fields';
import { action } from 'mobx';
import { FuelStore } from '../../../Shared/Stores/Fuel.store';
import { styles } from './UpsertFuel.styles';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { IAPIUpsertFuelRequest } from '../../../Shared';
import { IClasses, UIStore } from '@wings-shared/core';
import { EDITOR_TYPES, ViewInputControl, IViewInputControl, IGroupInputControls } from '@wings-shared/form-controls';

type Props = {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  fuelStore?: FuelStore;
  upsertFuel: (request: IAPIUpsertFuelRequest) => void;
  fuel?: FuelModel;
};

@inject('fuelStore')
@observer
class UpsertFuel extends BaseUpsertComponent<Props, FuelModel> {
  constructor(props: Props) {
    super(props, fields);
  }

  componentDidMount() {
    this.setFormValues(this.props.fuel as FuelModel);
  }

  @action
  private upsertFuel(): void {
    const { upsertFuel, fuel } = this.props;
    const fuels = new FuelModel({ ...fuel, ...this.form.values() });
    const { customerName, uwaCustomerId, wfsCustomerId, id } = fuels;
    const request: IAPIUpsertFuelRequest = {
      CustomerName: customerName,
      UWACustomerId: uwaCustomerId,
      WFSCustomerId: wfsCustomerId,
    };
    upsertFuel(id ? { ...request, Id: id } : request);
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'Fuel',
      inputControls: [
        {
          fieldKey: 'uwaCustomerId',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'wfsCustomerId',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'customerName',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
      ],
    };
  }

  private get hasError(): boolean {
    return this.form.hasError || UIStore.pageLoading;
  }

  /* istanbul ignore next */
  private get dialogContent(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        {this.loader.spinner}
        <div className={classes.modalDetail}>
          {this.groupInputControls.inputControls.map((inputControl: IViewInputControl, index: number) => (
            <ViewInputControl
              {...inputControl}
              key={index}
              classes={{
                flexRow: classes.fullFlex,
              }}
              field={this.getField(inputControl.fieldKey || '')}
              isEditable={this.isEditable}
              onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
            />
          ))}
          <div className={classes.btnContainer}>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.upsertFuel()}
              disabled={this.hasError}
            >
              Save
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }

  render(): ReactNode {
    const { classes, viewMode } = this.props as Required<Props>;
    return (
      <Dialog
        title={`${viewMode === VIEW_MODE.NEW ? 'Add' : 'Edit'} Fuel`}
        open={true}
        classes={{
          dialogWrapper: classes.modalRoot,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
      />
    );
  }
}

export default withStyles(styles)(UpsertFuel);
