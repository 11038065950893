import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IViewInputControl, IGroupInputControls } from '@wings-shared/form-controls';
import { FeatureNoteModel } from '../../../Shared';
import { fields } from './Fields';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { withStyles } from '@material-ui/core';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { styles } from './FeatureNoteDialog.styles';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { DATE_FORMAT, IClasses, ISelectOption, UIStore } from '@wings-shared/core';

type Props = {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  featureNotes?: FeatureNoteModel[];
  addFeatureNote: (featureNote: FeatureNoteModel) => void;
};

@observer
class FeatureNoteDialog extends BaseUpsertComponent<Props, FeatureNoteModel> {
  constructor(props: Props) {
    super(props, fields);
  }

  componentDidMount() {
    this.setFormValues(new FeatureNoteModel());
  }

  /* istanbul ignore next */
  private get loadCategories(): ISelectOption[] {
    const featureNotes = this.props.featureNotes as FeatureNoteModel[];
    return featureNotes
      ?.map(item => item.category?.value as string)
      .filter((value, index, self) => value && self.indexOf(value) === index)
      .map(item => ({ label: item, value: item }));
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'FeatureNote',
      inputControls: [
        {
          fieldKey: 'startDate',
          type: EDITOR_TYPES.DATE_TIME,
          dateTimeFormat: DATE_FORMAT.GRID_DISPLAY,
          allowKeyboardInput: false,
        },
        {
          fieldKey: 'title',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'category',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.loadCategories,
          freeSolo: true,
          isExists: Boolean(this.customErrorMessage),
          customErrorMessage: this.customErrorMessage,
        },
        {
          fieldKey: 'isInternal',
          type: EDITOR_TYPES.CHECKBOX,
        },
      ],
    };
  }

  private get customErrorMessage(): string {
    if (!this.getField('category').value?.label?.trim() && this.getField('category').touched)
      return 'The Category field is required.';
    return this.getField('category').value?.label?.length > 100 ? 'The Category field must be between 1 and 100.' : '';
  }

  @action
  private addFeatureNote(): void {
    const { addFeatureNote } = this.props;
    const featureNote = new FeatureNoteModel({ ...this.form.values() });
    addFeatureNote(featureNote);
  }

  private get hasError(): boolean {
    return this.form.hasError || UIStore.pageLoading || Boolean(this.customErrorMessage);
  }

  /* istanbul ignore next */
  private get dialogContent(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        {this.loader.spinner}
        <div className={classes.modalDetail}>
          {this.groupInputControls.inputControls.map((inputControl: IViewInputControl, index: number) => (
            <ViewInputControl
              {...inputControl}
              key={index}
              classes={{
                flexRow: classes.fullFlex,
              }}
              field={this.getField(inputControl.fieldKey || '')}
              isEditable={true}
              onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
            />
          ))}
          <div className={classes.btnContainer}>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.addFeatureNote()}
              disabled={this.hasError}
            >
              Add
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <Dialog
        title="Add Feature Note"
        open={true}
        classes={{
          dialogWrapper: classes.modalRoot,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
      />
    );
  }
}

export default withStyles(styles)(FeatureNoteDialog);
