import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { styles } from './RetailData.style';
import { withStyles, Typography, Theme } from '@material-ui/core';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ColDef, GridOptions, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import UpsertRetailData from './Components/UpsertRetailData/UpsertRetailData';
import { IAPIRetailDataOptionsResponse, RetailDataModel, RetailDataOptions, RetailDataStore } from '../Shared';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import moment from 'moment';
import { DatabaseIcon } from '@uvgo-shared/icons';
import { DATE_FORMAT, IClasses, UIStore, Utilities, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { AgGridViewRenderer, CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  retailDataStore?: RetailDataStore;
};

@inject('retailDataStore')
@observer
class RetailData extends BaseGrid<Props, RetailDataModel> {
  constructor(props) {
    super(props);
    this.data = [];
  }
  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  @action
  loadInitialData() {
    UIStore.setPageLoader(true);
    this.props.retailDataStore
      ?.getRetailData()
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe((data: RetailDataModel[]) => (this.data = data));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Job Id',
      field: 'id',
    },
    {
      headerName: 'User Name',
      field: 'username',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(
          moment.utc(value).local().format(DATE_FORMAT.API_FORMAT),
          DATE_FORMAT.SDT_DST_FORMAT
        ),
    },
    {
      headerName: 'End Date',
      field: 'endDate',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'viewRenderer',
      filter: false,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode) => this.viewRenderer(node.data),
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      minWidth: 160,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [{ title: 'Selections', action: GRID_ACTIONS.EDIT }],
        onAction: (action: GRID_ACTIONS, rowIndex: number, node: RowNode) => {
          this.gridActions(action, rowIndex);
        },
      },
    },
  ];

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    const retailData = this._getTableItem(rowIndex);
    if (gridAction === GRID_ACTIONS.EDIT) {
      this.openRetailDataDialog(VIEW_MODE.EDIT, this.data.find(x => retailData.id == x.id)?.option);
    }
  }

  /* istanbul ignore next */
  @action
  private upsertRetailData(upsertRetailDataOptionsResponse: IAPIRetailDataOptionsResponse): void {
    const { retailDataStore } = this.props;
    UIStore.setPageLoader(true);
    retailDataStore
      ?.upsertRetailData(upsertRetailDataOptionsResponse)
      .pipe(
        switchMap(() => retailDataStore.getRetailData()),
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: response => (this.data = response),
        error: (error: AxiosError) => AlertStore.info(error.message),
      });
  }

  /* istanbul ignore next */
  private openRetailDataDialog(mode: VIEW_MODE, retailData?: RetailDataOptions): void {
    const { retailDataStore } = this.props;
    ModalStore.open(
      <UpsertRetailData
        retailDataStore={retailDataStore}
        viewMode={mode}
        retailData={retailData}
        upsertRetailData={(upsertRetailDataOptionsResponse: IAPIRetailDataOptionsResponse) =>
          this.upsertRetailData(upsertRetailDataOptionsResponse)
        }
      />
    );
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    return {
      ...this._gridOptionsBase({
        context: this,
        columnDefs: this.columnDefs,
        isEditable: false,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => this.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      frameworkComponents: {
        viewRenderer: AgGridViewRenderer,
        actionRenderer: AgGridActions,
      },
      pagination: true,
    };
  }

  private viewRenderer(rowData: RetailDataModel): ReactNode {
    const classes = this.props.classes as IClasses;
    let node: ReactNode;
    switch (rowData.status) {
      case 'ENQUEUED':
        node = <div className={classes.enqueued}>Enqueued</div>;
        break;
      case 'PROCESSING':
        node = <div className={classes.processing}>Processing</div>;
        break;
      case 'COMPLETED':
        node = <div className={classes.completed}>Completed</div>;
        break;
      default:
        node = <div className={classes.failed}>Failed</div>;
        break;
    }
    return node;
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.subSection}>
            <DatabaseIcon />
            <Typography component="h3" className={classes.heading}>
              Retail Data
            </Typography>
          </div>
          <div>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.openRetailDataDialog(VIEW_MODE.NEW, new RetailDataOptions())}
            >
              Run Retail Data
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact rowData={this.data} gridOptions={this.gridOptions} />
          </div>
        </div>
      </>
    );
  }
}

export { RetailData as PureRetailData };
export default withStyles(styles)(RetailData);
