import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { withStyles } from '@material-ui/core';
import { styles } from './OptionFieldGrid.style';
import { SettingOptionsModel } from '../../../Shared';
import { IClasses, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { ChildGridWrapper, ConfirmDialog } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  optionsField: SettingOptionsModel[];
  openOptionFieldDialog: (optionsField: SettingOptionsModel, viewMode: VIEW_MODE) => void;
  upsertOptionField: (optionsField: SettingOptionsModel) => void;
  deleteOptionField: (id: number) => void;
}

@observer
class OptionFieldGrid extends BaseGrid<Props, SettingOptionsModel> {
  constructor(props) {
    super(props);
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        const data: SettingOptionsModel = this._getTableItem(rowIndex);
        this.props.openOptionFieldDialog(data, VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.CANCEL:
        this._cancelEditing(rowIndex);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveOptionField(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        break;
    }
  }

  private confirmRemoveOptionField(rowIndex: number): void {
    const data: SettingOptionsModel = this._getTableItem(rowIndex);
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this option?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.props.deleteOptionField(data.id)}
      />
    );
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Key',
      field: 'keyName',
    },
    {
      headerName: 'Value',
      field: 'value',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.value || value
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.value || ''
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      minWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: false,
            action: GRID_ACTIONS.EDIT,
          },
          {
            title: 'Delete',
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      showDeleteButton: true,
      getDisabledState: () => this.hasError,
      getEditableState: () => false,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: this.gridActionProps,
    });
    return {
      ...baseOptions,
      suppressClickEdit: true,
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
        minWidth: 180,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
      },
    };
  }

  public render(): ReactNode {
    return (
      <div className={this.props.classes?.container}>
        <ChildGridWrapper
          onAdd={() => this.props.openOptionFieldDialog(new SettingOptionsModel(), VIEW_MODE.NEW)}
          hasAddPermission={true}
          disabled={this.isProcessing || this.props.optionsField.length > 19}
        >
          <CustomAgGridReact
            isRowEditing={this.isRowEditing}
            rowData={this.props.optionsField}
            gridOptions={this.gridOptions}
          />
        </ChildGridWrapper>
      </div>
    );
  }
}

export default withStyles(styles)(OptionFieldGrid);
export { OptionFieldGrid as PureOptionFieldGrid };
