import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';
import { styles } from './MobileRelease.styles';
import { withStyles, Typography, Theme } from '@material-ui/core';
import MobileFriendlyOutlinedIcon from '@material-ui/icons/MobileFriendlyOutlined';
import { PrimaryButton } from '@uvgo-shared/buttons';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ColDef, GridOptions, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { MobileReleaseModel } from '../Shared/Models/MobileRelease.model';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { inject, observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { action } from 'mobx';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import UpsertRelease from './Components/UpsertRelease/UpsertRelease';
import { MobileReleaseStore, IAPIUpsertMobileReleaseRequest } from '../Shared';
import { DATE_FORMAT, IClasses, UIStore, Utilities, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { ConfirmDialog } from '@wings-shared/layout';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  mobileReleaseStore?: MobileReleaseStore;
};

@inject('mobileReleaseStore')
@observer
class MobileRelease extends BaseGrid<Props, MobileReleaseModel> {
  constructor(props) {
    super(props);
    this.data = [];
  }
  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  @action
  loadInitialData() {
    UIStore.setPageLoader(true);
    this.props.mobileReleaseStore
      ?.getMobileRelease()
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe((data: MobileReleaseModel[]) => (this.data = data));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Version',
      field: 'version',
    },
    {
      headerName: 'Date',
      field: 'date',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.SDT_DST_FORMAT),
    },
    {
      headerName: 'Force Update',
      field: 'forceUpdate',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        if (value === undefined) {
          return '';
        }
        return value ? 'Yes' : 'No';
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      minWidth: 160,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          { title: 'Edit', action: GRID_ACTIONS.EDIT },
          { title: 'Delete', action: GRID_ACTIONS.DELETE },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number, node: RowNode) => {
          this.gridActions(action, rowIndex);
        },
      },
    },
  ];

  /* istanbul ignore next */
  @action
  private deleteRelease(mobileReleases: MobileReleaseModel): void {
    UIStore.setPageLoader(true);
    this.props.mobileReleaseStore
      ?.deleteRelease(mobileReleases.mobileReleaseId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        }),
        filter((isDeleted: boolean) => isDeleted)
      )
      .subscribe(
        () => {
          this._removeTableItems([ mobileReleases ]);
          AlertStore.info('Mobile Release deleted successfully');
        },
        (error: AxiosError) => AlertStore.info(error.message)
      );
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }

    const mobileRelease = this._getTableItem(rowIndex);
    if (gridAction === GRID_ACTIONS.EDIT) {
      this.openReleasesDialog(VIEW_MODE.EDIT, mobileRelease);
    }

    if (gridAction === GRID_ACTIONS.DELETE) {
      ModalStore.open(
        <ConfirmDialog
          title="Confirm Delete"
          message="Are you sure you want to delete this release?"
          yesButton="Yes"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => this.deleteRelease(mobileRelease)}
        />
      );
    }
  }

  /* istanbul ignore next */
  @action
  private upsertRelease(upsertReleaseRequest: IAPIUpsertMobileReleaseRequest): void {
    const { mobileReleaseStore } = this.props;
    UIStore.setPageLoader(true);
    mobileReleaseStore
      ?.upsertRelease(upsertReleaseRequest)
      .pipe(
        switchMap(() => mobileReleaseStore.getMobileRelease()),
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe({
        next: response => (this.data = response),
        error: (error: AxiosError) => AlertStore.info(error.message),
      });
  }

  /* istanbul ignore next */
  private openReleasesDialog(mode: VIEW_MODE, mobileRelease?: MobileReleaseModel): void {
    ModalStore.open(
      <UpsertRelease
        mobileReleaseStore={this.props.mobileReleaseStore}
        viewMode={mode}
        mobileRelease={mobileRelease}
        upsertRelease={(upsertReleaseRequest: IAPIUpsertMobileReleaseRequest) =>
          this.upsertRelease(upsertReleaseRequest)
        }
      />
    );
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    return {
      ...this._gridOptionsBase({
        context: this,
        columnDefs: this.columnDefs,
        isEditable: false,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => this.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      frameworkComponents: {
        actionRenderer: AgGridActions,
      },
      pagination: false,
    };
  }

  render(): ReactNode {
    const classes = this.props.classes as IClasses;
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.subSection}>
            <MobileFriendlyOutlinedIcon className={classes.icon} />
            <Typography component="h3" className={classes.heading}>
              Mobile Releases
            </Typography>
          </div>
          <div>
            <PrimaryButton
              variant="contained"
              color="primary"
              onClick={() => this.openReleasesDialog(VIEW_MODE.NEW, new MobileReleaseModel())}
              startIcon={<AddIcon />}
            >
              Add Release
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact rowData={this.data} gridOptions={this.gridOptions} />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(MobileRelease);
